@import '~antd/dist/antd.less';

.logo {
  height: 40px;
  margin: 12px;
  text-align: center;

  img {
    height: 40px;
  }
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    line-height: 50px;
    width: 50%;
    display: inline-block;

    .title {
      width: 120px;
      display: inline-block;
    }
  }
}

.ant-btn-link {
  padding: 0 4px !important;
}

.ant-input-group-addon {
  background-color: transparent;
}

.editor {
  border: 1px solid #d9d9d9;
}
