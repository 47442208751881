.content {
  position: relative;
  height: 100%;

  .category {
    position: absolute;
    left: 0;
    width: 300px;
    top: 0;
    bottom: 20px;

    .plus {
      font-size: 20px;
      color: #1890ff;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px 20px;
        cursor: pointer;
        position: relative;

        .delete {
          font-size: 18px;
          position: absolute;
          right: 20px;
          top: 0;
          padding: 12px 0;
          display: none;
          z-index: 1;
        }

        &:hover {
          color: white;
          background-color: rgba(24, 144, 255, 0.3);

          .delete {
            display: block;
          }
        }

        &.active {
          color: white;
          background-color: #1890ff;
        }
      }
    }
  }

  .template {
    margin-left: 320px;
  }
}
