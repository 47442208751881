.login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  min-width: 300px;
  height: 230px;
}

.login-container .login-form {
  max-width: 300px;
  height: 100%;
}

.login-container .login-form-forgot {
  float: right;
}

.login-container .ant-col-rtl .login-form-forgot {
  float: left;
}

.login-container .login-form-button {
  width: 100%;
}
