.header {
  padding: 0 20px;
  margin: 0 -20px;
  background-color: white;
  line-height: 50px;
  border-bottom: 1px solid #dddfe2;

  h3 {
    display: inline;

    a {
      line-height: 50px;
    }
  }

  .content {
    float: right;
  }
}
