.fields {
  .field {
    position: relative;
    border: 1px dashed rgb(217, 217, 217);
    margin-bottom: 20px;
    padding-top: 20px;
    padding-right: 20px;
  }

  .cancel {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
