.tools {
  text-align: right;
  padding: 20px 0 10px 0;

  :global {
    button {
      margin-left: 10px;
      margin-bottom: 10px;

      &.ant-input-search-button {
        margin-left: 0;
      }
    }

    .ant-input-search {
      width: 200px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .ant-select {
      margin-left: 10px;
      margin-bottom: 10px;
      min-width: 150px;
    }
  }
}
