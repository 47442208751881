.create-message {
  .breadcrumb {
    margin: 12px 0;

    .breadcrumb-item {
      cursor: pointer;

      &:hover {
        color: #4390f7;
      }
    }
  }

  .form {
    background-color: #fff;
    margin: 24px 0;
    padding: 24px 0;
  }
}
